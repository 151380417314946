<template>
  <div class="home">
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eu iaculis
      mi. Aenean eu semper augue, elementum blandit est. Sed eget diam dapibus
      eros iaculis interdum et ut est. Duis turpis nisl, pharetra quis fringilla
      eget, vulputate a lacus. Lorem ipsum dolor sit amet, consectetur
      adipiscing elit. Donec rutrum aliquam felis eu laoreet. Morbi nec
      dignissim lorem. Class aptent taciti sociosqu ad litora torquent per
      conubia nostra, per inceptos himenaeos. Ut eu mollis metus. Aenean
      ultricies nec justo et consequat. Nunc commodo dui id ex eleifend, eu
      aliquam metus eleifend. Curabitur dictum dignissim interdum. Nam odio
      neque, hendrerit eu tempus ut, dignissim eget risus. Morbi molestie orci
      sed diam facilisis facilisis. Mauris placerat ultrices orci in
      condimentum. Praesent id luctus ex. In maximus orci sit amet velit finibus
      porttitor. Integer dignissim in ligula eget egestas. Aenean vel massa in
      ex semper ultricies vel in lectus. Phasellus efficitur arcu ac purus
      lacinia sollicitudin.
    </p>
  </div>
</template>
<script>
export default {
  name: "HomeView",
  components: {},
};
</script>
