<template>
  <div class="private">
    <h1>Private</h1>
    <p>private page</p>
  </div>
</template>
<script>
export default {
  name: "PrivatePage",
  components: {},
};
</script>
