<template>
  <div class="login">
    <h1>Login</h1>
    <form @submit.prevent="submit">
      <div>
        <label for="username"> Username </label>
        <input
          type="text"
          name="username"
          id="username"
          v-model="form.username"
        />
      </div>
      <div>
        <label for="password"> Password </label>
        <input
          type="text"
          name="password"
          id="password"
          v-model="form.password"
        />
      </div>
      <input type="submit" value="Login" />
    </form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "LoginPage",
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    async submit() {
      this.login(this.form)
        .then(() => {
          this.$router.replace({ name: "home" });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
