import axios from "axios";

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
  },
  getters: {
    authenticated(state) {
      return state.token && state.user;
    },
    user(state) {
      return state.user;
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    async login({ dispatch }, credentials) {
      let res = await axios.post(
        process.env.VUE_APP_AUTH_WORKER + "auth",
        credentials
      );

      const token = res.data;

      dispatch("attempt", token.token);
    },
    async attempt({ commit, state }, token) {
      if (token) commit("SET_TOKEN", token);

      if (!state.token) return;

      try {
        let res = await axios.get(process.env.VUE_APP_AUTH_WORKER + "auth");

        const user = res.data;

        commit("SET_USER", user);
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },
    logout({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
    },
  },
};
