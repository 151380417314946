<template>
  <nav>
    <router-link to="/">Home</router-link>
    <router-link to="/private" v-if="authenticated">Private</router-link>
    <router-link to="/about">About</router-link>
    <router-link to="/login" v-if="!authenticated">Login</router-link>
    <a v-if="authenticated">{{ user.username }}</a>
    <a href="#" v-if="authenticated" @click.prevent="logout">Logout</a>
  </nav>
</template>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  a + a {
    margin-left: 2em;
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      doLogout: "auth/logout",
    }),
    logout() {
      this.doLogout().then(() => {
        this.$router.replace({ name: "login" });
      });
    },
  },
};
</script>
